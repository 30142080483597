<template>
  <div class="d-block d-md-flex">
    <div class="form__section py-10 pt-md-11 px-6 px-md-7">
      <v-form ref="form">
        <div class="d-md-flex justify-space-between">
          <div class="pr-md-4">
            <p class="mb-0 sub__title">Total amount</p>
            <h2
              class="mb-md-0 amount"
              @click.prevent="toggleField"
              v-if="changeState"
            >
              {{ invoice.total_amount | currency(configSymbol) }}
            </h2>
            <v-text-field
              @blur="toggleField"
              v-if="!changeState"
              v-model.number="initialAmount"
              :background-color="backgroundColor"
              :rules="rules.amount"
              min="0"
              solo
              :autofocus="true"
              flat
              dense
              hide-details="auto"
            >
            </v-text-field>
          </div>
          <v-chip
            v-if="$vuetify.breakpoint.mdAndUp"
            text-color="#2BD5AE"
            style="background-color: #eefcf9"
            class="chipStyle px-2"
            >{{ invoice.invoiceType }}</v-chip
          >
        </div>

        <div class="customer-details">
          <p class="mb-0 pt-2 pb-6 section__title">Customer Details</p>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 mt-md-3 form__label">Select Customer</p>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <v-select
                v-model="invoice.customer"
                :items="filteredCustomers"
                item-value="id"
                item-text="company"
                :menu-props="{ searchable: true, lazy: true }"
                prepend-inner-icon="mdi-magnify"
                :disabled="review"
                :rules="rules.required"
                solo
                reverse
                flat
                dense
                hide-details
                :background-color="inputBackgroundColor"
              >
              </v-select>
            </v-col>
          </v-row>
          <hr class="hr-line" />
          <div class="text-right pt-1 pb-3">
            <span class="link__text" @click="OpenCustomerModal"
              >+ New Customer</span
            >
          </div>
          <hr class="hr-line" />
        </div>
        <div class="invoice-details pt-4 pt-md-8">
          <div class="">
            <v-row class="pt-2 pb-2">
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0 mt-md-2 form__label">Invoice no.</p>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="py-1 ma-0">
                <v-text-field
                  v-model="invoice.invoicenumber"
                  hide-details="auto"
                  :background-color="inputBackgroundColor"
                  solo
                  flat
                  dense
                  reverse
                  placeholder="invoice number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>
          <div class="">
            <v-row class="pt-4 pb-2">
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0 mt-md-2 form__label">Purchase order</p>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="py-1 ma-0">
                <v-text-field
                  v-model="invoice.po"
                  hide-details="auto"
                  :background-color="inputBackgroundColor"
                  solo
                  flat
                  dense
                  reverse
                  placeholder="Purchase order"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>

          <div>
            <v-row class="pt-4 pb-1">
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0 mt-md-3 form__label">Date</p>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="py-1 ma-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="invoice.date"
                      hide-details="auto"
                      :disabled="review"
                      :rules="rules.required"
                      :background-color="inputBackgroundColor"
                      solo
                      flat
                      dense
                      v-bind="attrs"
                      v-on="on"
                      reverse
                      placeholder="Enter Date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.date"
                    @input="menu = false"
                    :min="getCurrentDate()"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>

          <div>
            <v-row class="pt-4">
              <v-col cols="10" sm="6" md="4">
                <p class="mb-0 mt-md-3 form__label">VAT</p>
              </v-col>
              <v-col cols="2" sm="6" md="8" class="py-0 ma-0">
                <div
                  class="pb-4 d-flex align-center justify-center justify-md-end"
                >
                  <v-switch
                    v-if="$vuetify.breakpoint.mdAndUp"
                    true-value="1"
                    false-value="0"
                    hide-details="auto"
                    color="primary"
                    v-model="invoice.vat"
                    :disabled="review"
                  ></v-switch>
                  <v-checkbox
                    v-if="$vuetify.breakpoint.smAndDown"
                    true-value="1"
                    false-value="0"
                    v-model="invoice.vat"
                    color="primary"
                    hide-details="auto"
                    :disabled="review"
                  >
                  </v-checkbox>
                </div>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>
          <div>
            <v-row class="pt-4 pb-1">
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0 mt-md-3 form__label">Due Date</p>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="py-1 ma-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedDateOption"
                      hide-details="auto"
                      :disabled="review"
                      :background-color="inputBackgroundColor"
                      :rules="rules.required"
                      solo
                      flat
                      dense
                      v-bind="attrs"
                      v-on="on"
                      reverse
                      placeholder="Select Due Date"
                      @click:append="menu2 = !menu2"
                    ></v-text-field>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(option, index) in dropdownOptions"
                      :key="index"
                      @click="selectOption(option)"
                    >
                      <v-list-item-title>{{ option }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-date-picker
                  v-if="selectedDateOption === 'Custom Date'"
                  v-model="customDate"
                  @input="setCustomDate"
                  no-title
                  scrollable
                  :min="getCurrentDate()"
                  @change="menu2 = false"
                >
                </v-date-picker>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>

          <div>
            <v-row class="pt-4 pb-1">
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0 mt-md-3 form__label">Currency</p>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="py-1 ma-0">
                <v-select
                  v-model="invoice.currency"
                  :items="currencyItems"
                  hide-details="auto"
                  :disabled="review"
                  :background-color="inputBackgroundColor"
                  :rules="rules.required"
                  solo
                  flat
                  dense
                  reverse
                  placeholder="Select Currency"
                >
                </v-select>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>
          <div class="pt-4">
            <p class="mb-0 pb-6 section__title">Invoice Items</p>
            <div v-for="(line, index) in invoice.lines" :key="`Input-${index}`">
              <v-row>
                <v-col cols="7" class="pr-md-0 pb-4">
                  <v-text-field
                    v-model="line.description"
                    hide-details="auto"
                    :disabled="review"
                    :background-color="inputBackgroundColor"
                    :rules="rules.required"
                    solo
                    flat
                    dense
                    placeholder="Item"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="5" class="pl-md-0 pt-md-3">
                  <div class="d-flex d-md-flex align-center pl-1">
                    <v-btn
                      v-if="invoice.lines[index].quantity > 0"
                      x-small
                      color="primary"
                      :disabled="review"
                      icon
                      @click="decreaseQty(index)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>

                    <p class="quantity mb-0 px-2">{{ line.quantity }}</p>
                    <v-btn
                      x-small
                      color="primary"
                      :disabled="review"
                      icon
                      @click="increaseQty(index)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <v-text-field
                      class="ml-1"
                      v-model.number="line.price"
                      hide-details="auto"
                      :disabled="review"
                      :background-color="inputBackgroundColor"
                      :rules="rules.price"
                      solo
                      flat
                      dense
                      placeholder="Price"
                    >
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <hr class="hr-line" />
            </div>
            <div class="d-flex justify-space-between pt-1 pb-3">
              <p
                class="remove__text text-capitalize"
                text
                @click="removeInput(invoice.lines)"
                :disabled="review"
                v-show="invoice.lines && invoice.lines.length > 1"
              >
                - last item
              </p>
              <p></p>

              <p
                class="link__text text-right text-capitalize"
                text
                @click="addInput"
                :disabled="review"
              >
                + New Item
              </p>
            </div>
            <hr class="hr-line" />
          </div>
          <!-- <div>
                        <div class="d-flex align-center justify-space-between py-2">
                            <div class="d-flex align-center justify-space-between text-no-wrap">
                                <p class="mb-0 section__title text-nowrap">Addons</p>
                                <div class="custom-select" @click="toggleDropdown" ref="parentSelectContainer">
                                    <div class="selected-option">
                                        {{ selectedColumn.text }}
                                        <v-icon class="icon">mdi-menu-down</v-icon>
                                    </div>
                                    <div v-if="isOpen" class="options" ref="menu">
                                        <div v-for="(type, index) in columnType" :key="index"
                                            @click="selectColOption(type)">
                                            {{ type.text }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <v-switch class="ma-0" hide-details></v-switch>
                        </div>
                        <span v-if="addOnCol">
                            <div v-for="(line, index) in optionalLinesArray" :key="`Input-${index}`">
                                <v-row>
                                    <v-col cols="8" class="pr-md-0 pb-4">
                                        <v-text-field v-model="line.label" hide-details="auto" :disabled="review"
                                            :background-color="inputBackgroundColor" :rules="rules.required" solo flat dense
                                            placeholder="Item">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="pl-md-0 pt-md-3">
                                        <div class="d-flex d-md-flex align-center">
                                            <v-text-field class="ml-1" v-model.number="line.key" hide-details="auto"
                                                :disabled="review" reverse :background-color="inputBackgroundColor"
                                                :rules="rules.required" solo flat dense placeholder="0000">
                                            </v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                                <hr class="hr-line" />
                            </div>
                        </span>
                        <hr class="hr-line" v-if="optionalLinesArray.length < 1" />
                    </div> -->
          <div class="pt-4">
            <p class="section__title">Addons</p>
            <div>
              <v-row>
                <v-col
                  cols="8"
                  class="d-flex align-center justify-between py-1"
                >
                  <div
                    class="
                      switchContainer
                      d-flex
                      align-center
                      justify-space-between
                    "
                  >
                    <span class="columnTitle">Goods Received?</span>
                    <v-spacer></v-spacer>
                    <v-switch
                      v-model="sendGR"
                      :disabled="review"
                      hide-details="true"
                      class="mt-0 mb-1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-text-field
                    class=""
                    hide-details="auto"
                    :disabled="!sendGR"
                    v-model="goodsReceived"
                    :background-color="inputBackgroundColor"
                    dense
                    outlined
                    placeholder="000000"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="8"
                  class="d-flex align-center justify-between py-1"
                >
                  <div
                    class="
                      switchContainer
                      d-flex
                      align-center
                      justify-space-between
                    "
                  >
                    <span class="columnTitle">Service Entry</span>
                    <v-spacer></v-spacer>
                    <v-switch
                      v-model="sendSE"
                      :disabled="review"
                      hide-details="true"
                      class="mt-0 mb-1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="4" class="py-1">
                  <v-text-field
                    class=""
                    hide-details="auto"
                    :disabled="!sendSE"
                    v-model="serviceEntry"
                    :background-color="inputBackgroundColor"
                    dense
                    outlined
                    placeholder="000000"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="8"
                  class="d-flex align-center justify-between py-1"
                >
                  <div
                    class="
                      switchContainer
                      d-flex
                      align-center
                      justify-space-between
                    "
                  >
                    <span class="columnTitle">Extra column</span>
                    <v-spacer></v-spacer>
                    <v-switch
                      hide-details="true"
                      :disabled="review"
                      v-model="addOnCol"
                      class="mt-0 mb-1"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="4" class="py-1">
                  <div
                    class="custom-select"
                    @click="toggleDropdown"
                    ref="parentSelectContainer"
                  >
                    <div class="selected-option">
                      {{ selectedColumn.text }}
                      <v-icon class="icon">mdi-menu-down</v-icon>
                    </div>
                    <div v-if="isOpen" class="options" ref="menu">
                      <div
                        v-for="(type, index) in columnType"
                        :key="index"
                        @click="selectColOption(type)"
                      >
                        {{ type.text }}
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <span v-if="addOnCol">
              <div
                v-for="(line, index) in optionalLinesArray"
                :key="`Input-${index}`"
              >
                <v-row>
                  <v-col cols="8" class="pr-md-0 pb-4">
                    <v-text-field
                      v-model="line.label"
                      hide-details="auto"
                      :disabled="review"
                      :background-color="inputBackgroundColor"
                      :rules="rules.required"
                      solo
                      flat
                      dense
                      placeholder="Item"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" class="pl-md-0 pt-md-3">
                    <div class="d-flex d-md-flex align-center">
                      <v-text-field
                        class="ml-1"
                        v-model.number="line.key"
                        hide-details="auto"
                        :disabled="review"
                        reverse
                        :background-color="inputBackgroundColor"
                        :rules="rules.required"
                        solo
                        flat
                        dense
                        placeholder="0000"
                      >
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <hr class="hr-line" />
              </div>
            </span>
            <hr class="hr-line" v-if="optionalLinesArray.length < 1" />
          </div>
          <div class="pt-4" v-if="$appCustomCollectionAcct">
            <p class="mb-0 pb-3 section__title">Bank Account</p>
            <v-select
              :items="filteredAccountArray"
              item-value="hypn_id"
              item-text="account"
              :menu-props="{ searchable: true, lazy: true }"
              :background-color="inputBackgroundColor"
              prepend-inner-icon="mdi-magnify"
              :hide-details="true"
              outlined
              dense
              v-model="invoice.bank"
              placeholder="Select an Account"
              class="hypn-input pb-4"
            >
              <template #item="{ item }">
                <div class="item-wrapper py-2">
                  <span>{{ item.account }}</span>
                  <small class="grey--text text--lighten-1">{{
                    item.bank
                  }}</small>
                </div>
              </template>
            </v-select>
            <hr class="hr-line" />
          </div>
        </div>
        <div
          class="d-flex justify-center align-center text-center pt-14"
          style=""
        >
          <v-btn
            block
            class="py-6"
            elevation="0"
            :disabled="invoiceIsSent"
            color="white"
            :loading="isLoading"
            @click="handleInvoice"
          >
            <v-icon>mdi-check</v-icon>
            <span class="pl-8 pr-14 text-capitalize">{{ toggleBtnText }}</span>
          </v-btn>
        </div>
        <div
          class="d-flex justify-center align-center text-center pt-6"
          style=""
          v-if="saveState > 0"
        >
          <v-btn
            block
            class="py-6"
            elevation="0"
            color="white"
            :loading="isDownloading"
            @click="
              handlePDFDownload(
                invoiceHypnID,
                dispatchPath,
                entityType,
                (send = false)
              )
            "
          >
            <img
              :src="require('@/assets/pbot_icons/arrow_down.svg')"
              class="navIcon pr-2"
            />
            <span class="pl-8 pr-14 text-capitalize">Download PDF</span>
          </v-btn>
        </div>
        <div class="text-center pt-6" style="padding-bottom: 20px">
          <v-btn
            block
            class="py-6"
            elevation="10"
            color="primary"
            :disabled="invoiceIsSent"
            @click="showSendInvoiceModal"
          >
            <simple-line-icons
              style="width: 12px; height: 12px"
              class="m-0 text--white pl-14"
              icon="cursor"
              medium
              no-svg
            />
            <span class="pl-12 pr-14 text-capitalize">Save & Send</span>
          </v-btn>
        </div>
      </v-form>
    </div>
    <div class="preview__section px-6 px-md-12">
      <div class="d-flex align-center pr-5 py-md-8" style="width: 100%">
        <v-breadcrumbs :items="breadcrumbs" class="px-md-2 breadcrumbs">
          <template v-slot:divider>
            <v-icon class="px-0">mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn
          @click="$router.go(-1)"
          text
          rounded
          large
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-icon left>mdi-chevron-left</v-icon> Back
        </v-btn>
        <attach-document
          :itemHypnID="invoiceHypnID"
          dispatchURI="invoices"
          entityType="invoice"
        >
          <v-btn
            text
            rounded
            large
            v-if="$vuetify.breakpoint.mdAndUp && invoiceHypnID"
          >
            <v-icon left>mdi-view-grid-outline</v-icon> Tools
          </v-btn>
        </attach-document>
      </div>
      <v-card v-if="newInvoiceData" class="px-6 py-6 px-md-8 py-md-12">
        <InvoiceTemplate
          :purchaseorder="newInvoiceData.po"
          :invoicenumber="newInvoiceData.invoicenumber"
          :company_from="organization.orgname"
          :address_from="organization.office[0].address || ''"
          :company_to="
            newInvoiceData.customer.company.company_name || 'customer name'
          "
          :total="newInvoiceData.total"
          :subTotal="newInvoiceData.subtotal"
          :lines="newInvoiceData.lines"
          :city_from="organization.office[0].city || ''"
          :country_from="organization.office[0].country || ''"
          :zip_from="organization.office[0].postalcode || ''"
          :date="new Date() | moment('DD-MM-YYYY')"
          :dueDate="newInvoiceData.due_date"
          :organizationLogo="organization.logo"
          :tax="newInvoiceData.vat"
          :addon="newInvoiceData.addon ? true : false"
          :se="newInvoiceData.se"
          :gr="newInvoiceData.gr"
          :currencySym="
            newInvoiceData.currency == 'NGN'
              ? '₦'
              : newInvoiceData.currency == 'USD'
              ? '$'
              : newInvoiceData.currency == 'EUR'
              ? '€'
              : '£'
          "
          :bankName="customerBankDetails ? customerBankDetails.bankname : ''"
          :bankNumber="
            customerBankDetails ? customerBankDetails.accountnumber : ''
          "
          :bankAccountName="
            customerBankDetails ? customerBankDetails.accountname : ''
          "
          :bankSortCode="
            customerBankDetails ? customerBankDetails.bankcode : ''
          "
        />
      </v-card>
      <v-card
        v-else
        v-model="review"
        class="px-6 py-6 px-md-12 py-md-12 d-flex justify-center align-center"
        elevation="6"
        style="width: 100%; min-height: 700px"
      >
        <div class="d-flex justify-center align-center">
          <p class="invoice__prev">invoice preview</p>
        </div>
      </v-card>
    </div>
    <AddCustomer ref="AddCustomer" />
    <SendCustomerInvoice ref="SendInvoiceModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddCustomer from "../../includes/overlays/ContactsMenuModals/AddCustomer.vue";
import SendCustomerInvoice from "@/pages/invoices/components/sendCustomerInvoice.vue";
import InvoiceTemplate from "./components/invoiceTemplate.vue";
import AttachDocument from "@/components/attachDocument.vue";
import downloadPdfMixin from "@/mixins/downloadPdfMixin";

export default {
  name: "NewInvoice",
  data() {
    return {
      dispatchPath: "contacts/sendCustomerInvoice",
      entityType: "invoice",
      menu: false,
      menu2: false,
      isLoading: false,
      isDownloading: false,
      changeState: false,
      organLogo: "",
      review: false,
      update: false,
      saveState: 0,
      initialAmount: 0,
      invoiceid: null,
      invoiceHypnID: "",
      selectedColumn: { text: "Material", value: "material" },
      canSendInvoice: false,
      invoiceIsSent: false,
      sendGR: false,
      sendSE: false,
      addOnCol: false,
      invoice: {
        invoicenumber: "",
        total_amount: 0,
        source: "",
        po: "",
        invoiceType: "Customer Invoice",
        date: this.getCurrentDate(),
        due_date: null,
        lines: [
          {
            description: "",
            quantity: 1,
            price: 0,
          },
        ],
        bank: "",
        currency: "",
      },
      currencyItems: ["NGN", "USD", "GBP", "EUR"],
      breadcrumbs: [
        {
          text: "Inbox",
          to: "/inbox",
          disabled: true,
        },
        {
          text: "Invoice",
          to: "#",
          disabled: true,
        },
        {
          text: "New Invoice",
          disabled: false,
        },
      ],
      rules: {
        required: [(v) => !!v || "This Field is required"],
        price: [(v) => !!v || "Price is required"],
        amount: [
          (v) => !!v || "Amount is required",
          (v) => v > 0 || "Amount must be greater than 0",
        ],
      },
      selectedDateOption: null,
      customDateMenu: false,
      customDate: null,
      dropdownOptions: [
        "30 Days",
        "45 Days",
        "60 Days",
        "90 Days",
        "Custom Date",
      ],
      columnType: [{ text: "Material", value: "material" }],

      isOpen: false,
      goodsReceived: "",
      serviceEntry: "",
    };
  },
  mixins: [downloadPdfMixin],
  computed: {
    //mapGetters to get the state of the store

    ...mapGetters({
      customers: "contacts/getCustomersCompanyName",
      organizationId: "auth/organizationId",
      organization: "organizations/organObj",
      newInvoiceData: "invoices/getNewInvoiceData",
      filteredAccountArray: "organizations/getAccountObject",
      customerBankDetails: "contacts/getCustomerBankDetails",
    }),

    // // filter the customers array using the search value..
    filteredCustomers() {
      let unSorted = this.customers
        ?.filter((item) => item.company !== null)
        .sort();
      return unSorted.sort((a, b) => a.company.localeCompare(b.company));
    },

    inputBackgroundColor() {
      return this.review ? "transparent" : "#ffffff";
    },
    backgroundColor() {
      return this.changeState ? "transparent" : "#ffffff";
    },

    toggleBtnText() {
      switch (this.saveState) {
        case 1:
          return "Review";
        case 2:
          return "Update";
        default:
          return "Save";
      }
    },

    //calculate the subtotal of price and quantity
    subTotal() {
      let total = 0;
      this.invoice.lines.forEach((line) => {
        total += line.quantity * Math.abs(line.price);
      });

      return total;
    },

    //get the lenght of the invoice lines array
    lineItems() {
      let x = this.invoice.lines.length;
      return x.toString();
    },

    //computed prop to get the create customer invoice payload
    createCustomerInvoicePayload() {
      let updatedLineItems = this.invoice.lines?.map((item, index) => {
        return {
          ...item,
          addon: this.optionalLinesArray[index].key,
        };
      });
      return {
        customer: this.invoice.customer,
        source: this.invoice.source,
        due_date: this.invoice.due_date,
        date: this.$moment(this.invoice.date).format("YYYY/MM/DD"),
        total: this.invoice.total_amount.toString(),
        subtotal: this.subTotal.toString(),
        vat: this.invoice.vat,
        organization: this.organizationId.toString(),
        line_items: this.lineItems,
        lines: updatedLineItems,
        bank: this.invoice.bank,
        currency: this.invoice.currency,
        addontype: this.addOnCol ? this.selectedColumn.value : "",
        gr: this.goodsReceived,
        se: this.serviceEntry,
        po: this.invoice.po,
      };
    },

    configSymbol() {
      return this.invoice.currency ? this.invoice.currency : "NGN";
    },

    // get an array of items for addOnCol that displays just the items from invoice.lines array and values
    optionalLinesArray() {
      let newLinesArray = [];
      newLinesArray = this.invoice.lines.map((item) => ({
        label: item.description,
        key: null,
      }));
      return newLinesArray;
    },
  },
  methods: {
    //mapActions
    ...mapActions({ showToast: "ui/showToast" }),

    selectOption(option) {
      if (option !== "Custom Date") {
        this.selectedDateOption = option;
        const days = parseInt(option.split(" ")[0]);
        // console.log("in days: " + days);
        this.invoice.due_date = days;
        // const dueDate = new Date();
        // dueDate.setDate(dueDate.getDate() + days);
        // this.invoice.due_date = dueDate.toISOString().substr(0, 10);

        console.log(this.invoice.due_date);
        this.menu2 = false;
      } else {
        this.selectedDateOption = option; // Set selected option to 'Custom Date'
        this.menu2 = false; // Hide the dropdown list
        // Reset customDate to null when 'Custom Date' is selected from the dropdown
        this.customDate = null;
      }
    },

    setCustomDate() {
      if (this.customDate) {
        // Calculate days from the current date when a custom date is selected
        const currentDate = new Date();
        const selectedDate = new Date(this.customDate);
        const differenceInTime = selectedDate.getTime() - currentDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        this.selectedDateOption = `${Math.floor(differenceInDays)} Days`;
        // Assign the selected date to the invoice due date
        this.invoice.due_date = Math.floor(differenceInDays);
        console.log(this.invoice.due_date);
        this.menu2 = false; // Hide the date picker after selection
      }
    },

    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    getMaxDate() {
      // Set a maximum allowed date for selection (e.g., 30 days from the current date)
      const today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
    handleInvoice() {
      switch (this.saveState) {
        case 0:
          this.saveInvoice();
          // this.review = true;

          break;
        case 1:
          // console.log("reviewing");
          this.review = false;
          this.saveState++;
          break;
        default:
          this.updateInvoice();
          // this.review = true;

          break;
      }
    },

    // async handlePDFDownload() {
    //   // console.log("handleDownloadPDF");
    //   console.log(this.invoiceid);
    //   this.isDownloading = true;
    //   const payload = {
    //     id: this.invoiceHypnID,
    //     send: false,
    //   };
    //   try {
    //     await this.$store
    //       .dispatch("contacts/sendCustomerInvoice", payload)
    //       .then((res) => {
    //         if (process.env.NODE_ENV === "development") {
    //           console.log(res);
    //         }
    //         this.showToast({
    //           sclass: "success",
    //           show: true,
    //           message: res.message || "Invoice generated successfully",
    //           timeout: 3000,
    //         });
    //         // open a new window tab with res.data.file as the url
    //         window.open(res.data.file, "_blank");
    //       });
    //   } catch (error) {
    //     console.log(error);
    //     this.showToast({
    //       sclass: "error",
    //       show: true,
    //       message: error.msg || "Error Generating Invoice",
    //       timeout: 3000,
    //     });
    //   } finally {
    //     this.isDownloading = false;
    //   }
    // },

    toggleField() {
      this.changeState = !this.changeState;
    },
    OpenCustomerModal() {
      this.$refs.AddCustomer.show(true);
      console.log("hello");
    },

    addInput() {
      this.invoice.lines.push({
        description: "",
        quantity: 1,
        price: 0,
      });
    },
    removeInput(inputType) {
      let index = -1;
      inputType.splice(index, 1);
    },

    //increase quantity
    increaseQty(index) {
      //check if quantity has a string value and change it to a number
      // if (this.invoice.lines[index].quantity === "QTY") {
      //   this.invoice.lines[index].quantity = 0;
      // }

      this.invoice.lines[index].quantity++;
    },
    //decrease quantity
    decreaseQty(index) {
      if (this.invoice.lines[index].quantity > 1) {
        this.invoice.lines[index].quantity--;
      }
    },
    //save invoice for preview
    async saveInvoice() {
      try {
        if (!this.$refs.form.validate()) {
          return; // Exit early if the form is not valid
        }

        this.isLoading = true;

        const data = await this.$store.dispatch(
          "invoices/createCustomerInvoice",
          this.createCustomerInvoicePayload
        );
        console.log(data.data.hypn_id);
        this.invoiceHypnID = data?.data?.hypn_id;
        if (!data) {
          throw new Error("No response data received"); // Handle the case where no data is returned
        }

        await this.$store.dispatch("contacts/getCustomerBankDetails", {
          organizationId: this.organizationId,
          customerId: data?.data?.customer?.id,
        });

        this.showToast({
          sclass: "success",
          show: true,
          message: "Customer invoice created successfully",
          timeout: 3000,
        });

        this.canSendInvoice = true;
        this.invoiceid = data.id;
        this.review = true;
        this.saveState++;
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }

        this.showToast({
          sclass: "error",
          show: true,
          message: error.message || "An error occurred",
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },

    // Update invoice for preview
    async updateInvoice() {
      try {
        if (!this.$refs.form.validate()) {
          return; // Exit early if the form is not valid
        }

        this.isLoading = true;

        // Calculate new due_date
        const daysToAdd = this.invoice.due_date;
        const newDueDate = this.calculateNewDate(daysToAdd);

        const requestData = {
          id: this.invoiceid,
          payload: {
            ...this.createCustomerInvoicePayload,
            due_date: newDueDate, // Update due_date with the calculated value
          },
        };

        const data = await this.$store.dispatch(
          "invoices/updateCustomerInvoice",
          requestData
        );

        this.showToast({
          sclass: "success",
          show: true,
          message: "Customer invoice updated successfully",
          timeout: 3000,
        });
        this.canSendInvoice = true;
        this.review = true;
        this.saveState--;

        console.log(data);
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
        }

        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "An error occurred during the update process",
          timeout: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },

    // Calculate the new due_date in YYYY/MM/DD format using the provided daysToAdd
    calculateNewDate(daysToAdd) {
      const dateParts = this.createCustomerInvoicePayload.date.split("/");
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);

      const date = new Date(year, month, day);
      date.setDate(date.getDate() + daysToAdd);

      const newYear = date.getFullYear();
      const newMonth = String(date.getMonth() + 1).padStart(2, "0");
      const newDay = String(date.getDate()).padStart(2, "0");

      return `${newYear}/${newMonth}/${newDay}`;
    },

    //check if invoice is saved and open the send invoice modal
    async showSendInvoiceModal() {
      if (this.canSendInvoice && !this.invoiceIsSent) {
        this.$refs.SendInvoiceModal.show(true);
        this.invoiceIsSent = true;
      } else {
        await this.saveInvoice();
        if (this.canSendInvoice && !this.invoiceIsSent) {
          this.$refs.SendInvoiceModal.show(true);
          this.invoiceIsSent = true;
        }
      }
      // console.log(this.canSendInvoice);
    },

    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectColOption(option) {
      this.selectedColumn = option;
    },

    //fetch all customers
    async getAllCustomers() {
      try {
        await this.$store.dispatch("contacts/fetchAllCustomers", this.$orgId);
      } catch (error) {
        if (process.env.NODE_ENV === "developement") {
          console.log(error);
        }
      }
    },
  },

  components: {
    AddCustomer,
    SendCustomerInvoice,
    InvoiceTemplate,
    AttachDocument,
  },

  async mounted() {
    if (this.newInvoiceData) {
      this.invoice = this.newInvoiceData;
    }

    if (this.customers.length === 0 && window !== undefined) {
      this.getAllCustomers();
    }
  },

  watch: {
    initialAmount: {
      handler(val) {
        // convert val to positive number
        val = Math.abs(val);

        this.invoice.lines[0].price = val;
        console.log(val);
      },
      immediate: true,
      deep: true,
    },

    //watch subTotal to calculate the total amount
    subTotal: {
      handler(val) {
        this.invoice.total_amount = val;
      },
      immediate: true,
      deep: true,
    },

    attachementPayload: {
      handler(val) {
        if (val) {
          console.log(JSON.stringify(val, null, 2));
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.form__section {
  width: 100%;
  background: #f2f2f2;

  .sub__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(25, 40, 61, 0.8);
  }

  .amount {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    color: #455160;
    cursor: pointer;
  }
}

.preview__section {
  width: 100%;
  height: 100%;
  //   min-height: 100vh;
  background: #f8f7f4;
}

.quantity {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #19283d;
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .form__section {
    width: 38%;
    min-height: 100vh;
    background: #f6f3ee;
    display: flex;
    flex-flow: column;

    .sub__title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: rgba(25, 40, 61, 0.8);
    }

    .amount {
      font-weight: 700;
      font-size: 22px;
      line-height: 18px;
    }
  }

  .preview__section {
    width: 62%;
    height: 100%;
    background: #f8f7f4;
  }

  .customer-details {
    padding-top: 64px;
  }
}

.section__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(25, 40, 61, 0.8);
}

.form__label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #19283d;
}

i.sli-font {
  font-size: 16px;
  display: inline-block;
}

.link__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #5b67ba;
  cursor: pointer;
}

.remove__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: red;
  cursor: pointer;
}

.link__text:hover {
  color: #515ca0;
  font-weight: 700;
}

.chipStyle {
  font-weight: 500;
}

.breadcrumbs {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #8f96a1;
}

.invoice__prev {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #7f919b;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.hr-line {
  border-top: 1px dashed rgba(40, 28, 75, 0.233);
}

.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

/* Adjust the width as needed */
.min-width-select .v-select__selections {
  min-width: 50px;
  /* Set the desired minimum width */
}

.custom-select {
  position: relative;
  display: inline-block;
  cursor: pointer;
  // margin-left: 32px;
  background-color: #fff;
  width: 100%;
}

.selected-option {
  padding: 8px;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-family: "Inter";
  font-weight: 600;
}

.icon {
  margin-left: auto;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 10;
  font-size: 12px;
}

.options div {
  padding: 8px;
}

.options div:hover {
  background-color: #f0f0f0;
}

.options.show {
  display: block;
}

.hypn-input >>> ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
  color: #8f96a1;
}

.columnTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: var(--v-primary-base);
}

.switchContainer {
  width: 70%;
}
</style>
